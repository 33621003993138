<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.wfDesign.form.title.editHeadTitle') : $t('cip.plat.wfDesign.form.title.addHeadTitle')"
        @head-save="headSave('save')"
        @head-save-back="headSave('back')"
        @head-cancel="headCancel">
      </head-layout>

      <form-layout
        :column="option.column"
        :dataForm="dataObj"
        ref="formLayout">
      </form-layout>
    </el-main>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {update, add,getDetail} from "@/api/plugin/workflow/form";
import {tree} from '@/api/plugin/workflow/category';

export default {
  components: {FormLayout, HeadLayout},

  data() {
    return {
      headBtnOptions: [
        {
          label: this.$t('cip.cmn.btn.saveBtn'),
          emit: "head-save",
          type: "button",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }
      ],
      dataObj: {},
      option: {
        menuWidth: 350,
        size: 'mini',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        index: true,
        selection: true,
        dialogType: 'drawer',
        align: 'center',
        searchMenuSpan: 6,
        column: [
          {
            label: this.$t('cip.plat.wfDesign.form.field.formKey'),
            prop: "formKey",
            maxlength: 100,
            showWordLimit:true,
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.form.field.formKey`),
              trigger: "blur"
            },{
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }],
            span: 8,
            search: true
          },
          {
            label: this.$t('cip.plat.wfDesign.form.field.name'),
            prop: "name",
            span: 8,
            maxlength: 100,
            showWordLimit:true,
            // isGlobal: true,
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.form.field.name`),
              trigger: "blur"
            }, {
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }],
            search: true
          },
          {
            label: this.$t('cip.plat.wfDesign.form.field.categoryId'),
            prop: 'categoryId',
            type: 'tree',
            span: 8,
            props: {
              label: 'name',
              value: 'id'
            },
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.form.field.categoryId`),
              trigger: "blur"
            }],
            dicData: [],
          },
          {
            label: this.$t('cip.plat.wfDesign.form.field.version'),
            prop: 'version',
            display: false
          },
          {
            label: this.$t('cip.plat.wfDesign.form.field.status'),
            prop: 'status',
            span: 8,
            type: 'select',
            value: 1,
            // dicData: [{
            //   label: this.$t('cip.plat.wfDesign.form.field.available'),
            //   value: 1
            // }, {
            //   label: this.$t('cip.plat.wfDesign.form.field.forbidden'),
            //   value: 2
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_available",
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.selectWarning`)+this.$t(`cip.plat.wfDesign.form.field.status`),
              trigger: "change"
            }],
            search: true,
            row: true,
          },
          {
            label: this.$t('cip.plat.wfDesign.form.field.remark'),
            prop: 'remark',
            type: 'textarea',
            maxlength: 500,
            span: 16,
            row: true,
          },
        ]
      },
    }
  },
  created() {
    if (this.$route.query.type == 'edit') {
      this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data))
    }

  },
  mounted() {
    this.getCategoryList()
  },
  methods: {
    getCategoryList() {
      tree().then(res => {
        const data = res.data.data
        const column = this.findObject(this.option.column, "categoryId");
        column.dicData = data;
      })
    },
    init(row){
      getDetail(row).then(res => {
          const {data} = res.data;
          this.dataForm = data;
        })
    },
    headSave(type) {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then((res) => {
            const {msg, data} = res.data;
            if (type == 'back') {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }else {
              this.dataObj=data;
              this.init(data)
              this.$refs.formLayout.$refs.form.allDisabled = false
              this.$message({
                type: "success",
                message: this.$t(`cip.cmn.msg.success.operateSuccess`),
              });
            }
            this.isShow = false
            //
          }, error => {
            window.console.log(error);

            this.$refs.formLayout.$refs.form.allDisabled = false
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style>
</style>
